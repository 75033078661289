@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,500;0,600;1,700&display=swap');

$primary: #005450;
$grey: #f3f4f3;
$control-grey: #dcdfde;

@import "../node_modules/bootstrap/scss/bootstrap";

body {
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    font-size: 13px;
    overflow-y: scroll;
}


.app {
    font-family: "Work Sans", sans-serif;
    font-size: 0.9rem;
    font-weight: 400;
    color: #005450;
}

.app a {
    color: #005450;
}

.chip {
    border-radius: 6px;
    background-color: #eaf1f1;
    color: #005450;
    display: inline-block;
    padding: 0.3em 0.5em;
    font-size: 0.9em;
    margin: 0 0.3em 0 0;
}

a.chip {
    text-decoration: none;
}

.table a {
    text-decoration: none;
}

.research-item {
    cursor: pointer;
}

.research-title {
    font-weight: 600;
    color: #005450;
}

.table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: #f8f8f8 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.nav-link {
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 0 1em;

    &:hover {
        color: #00d553;
    }
}

.offcanvas.show {
    background-color: #005450;
    padding: 2em 0;
}

.offcanvas-header .btn-close {
    color: #fff !important;
}

.offcanvas.show .nav-link {
    color: #fff !important;
}

.offcanvas-header button {
    opacity: 1;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
    width: 0.5em;
    height: 0.5em;
}

.offcanvas.show .offcanvas-body {
    text-align: center;
}

.rmsc .dropdown-container:focus-within {
    border-color: #80aaa8 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem #00545040 !important;
}

.select-dropdown .separator {
    height: 1px;
    background-color: #ccc;
    margin: 4px 0;
  }

// Record modal

dd {
    margin-bottom: 1rem !important;
}

hr {
    opacity: 0.1;
}